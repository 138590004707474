import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';

class PointsApiService {

  constructor() {
    if (!PointsApiService.instance) { PointsApiService.instance = this }
    return PointsApiService.instance
  }

  defaultAuthConfig = () => {
    const accessToken = authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  }


  /* fetchDataForTable = (fetchLimit, paginationBookmark, filterModel, sortModel, fullSearchText) => {
  var searchText, searchField, searchType = null;
      var sortField, sortOrder  = null;

      if (filterModel != null && Object.keys(filterModel) > 0) {
          searchText = filterModel.value;
          searchField = filterModel.columnField;
          searchType = filterModel.operatorValue;
      }
      if (sortModel != null && Object.keys(sortModel).length > 0) {
          console.log("Inside here", sortModel);
          sortField = sortModel.field;
          sortOrder = sortModel.sort === 'asc' ? 'ascending' : 'descending';
      }
      if (!fetchLimit) fetchLimit = 100;
      if (!paginationBookmark) paginationBookmark = 0;

      if (fullSearchText)
          searchText = fullSearchText;

  const url = `${appConfig.serverBaseUrl}/`;
  const config = { ...this.defaultAuthConfig(), params : { fetchLimit, paginationBookmark, filterModel, sortModel, fullSearchText } };
  return axios.get(url, config);

};
*/

  fetchCurrentRule = () => {
    const url = `${appConfig.serverBaseUrl}/points/merchants/config`;
    const config = { ...this.defaultAuthConfig(), params: {} };
    return axios.get(url, config);
  };

  updateCurrentRule = (currentRule) => {
    const url = `${appConfig.serverBaseUrl}/points/merchants/config`;
    const config = { ...this.defaultAuthConfig() };
    const data = { ...currentRule };

    return authService.axiosService().put(url, data, config);
  };

  // This is subjected to the changes, would be a statistics endpoint to do so.
  fetchOpsTransactions = (request) => {

    var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;


      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
      console.log("Current sort model", request.sortModel);
      if (request.sortModel.field && request.sortModel.sort) {
        sortField = request.sortModel.field;
        sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
      }
    }
    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark, actionType: request.actionType, transactionType: "ops" } } : {};
    const url = `${appConfig.serverBaseUrl}/transactions`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };

    return authService.axiosService().get(url, config);
  };

  fetchPointsRules = () => {
    const url = `${appConfig.serverBaseUrl}/points/rules`;
    const config = { ...this.defaultAuthConfig() };

    return authService.axiosService().get(url, config);
  };

  fetchPointsRule = (ruleId) => {
    const url = `${appConfig.serverBaseUrl}/points/rules/${ruleId}`;
    const config = { ...this.defaultAuthConfig() };

    return authService.axiosService().get(url, config);
  };

  fetchCurrentRule = () => {

    const url = `${appConfig.serverBaseUrl}/points/merchants/config`;
    const config = { ...this.defaultAuthConfig(), params: {} };
    return axios.get(url, config);

  };

  updateCurrentRule = (currentRule) => {
    const url = `${appConfig.serverBaseUrl}/points/merchants/config`;
    const config = { ...this.defaultAuthConfig() };
    const data = { ...currentRule };

    return authService.axiosService().put(url, data, config);
  };


  fetchTiers = (request) => {

    var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;


      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
      console.log("Current sort model", request.sortModel);
      if (request.sortModel.field && request.sortModel.sort) {
        sortField = request.sortModel.field;
        sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
      }
    }
    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark, actionType: request.actionType, transactionType: "ops" } } : {};

    const url = `${appConfig.serverBaseUrl}/members/tiers`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };

    return authService.axiosService().get(url, config);

  };


  fetchTiersById = (tierId) => {
    const url = `${appConfig.serverBaseUrl}/members/tiers/{tierId}`;
    const config = { ...this.defaultAuthConfig(), params: {} };
    return authService.axiosService().get(url, config);
  };

  updateTiers = (tier) => {
    const url = `${appConfig.serverBaseUrl}/members/tiers/${tier.id}`;
    const config = { ...this.defaultAuthConfig() };
    const data = { ...tier };

    return authService.axiosService().put(url, data, config);
  };


  updateTier = (tier) => {
    const url = `${appConfig.serverBaseUrl}/members/tiers/${tier.id}`;
    const config = { ...this.defaultAuthConfig() };
    const data = { ...tier };

    return authService.axiosService().put(url, data, config);
  };


  fetchProductCategories = () => {
    const url = `${appConfig.serverBaseUrl}/product-categories`;
    const config = { ...this.defaultAuthConfig() };

    return authService.axiosService().get(url, config);
  };

  createRule = (rule) => {
    const url = `${appConfig.serverBaseUrl}/points/rules`;
    const config = { ...this.defaultAuthConfig() };
    const request = { ...rule };

    return authService.axiosService().post(url, request, config);
  };

  updateRule = (rule) => {
    const url = `${appConfig.serverBaseUrl}/points/rules/${rule.id}`;
    const config = { ...this.defaultAuthConfig() };
    const request = { ...rule };

    return authService.axiosService().put(url, request, config);
  };

  fetchTiers = () => {
    const url = `${appConfig.serverBaseUrl}/members/tiers`;
    const config = { ...this.defaultAuthConfig() };

    return authService.axiosService().get(url, config);
  };


  updatePoints = (rows) => {
    const url = `${appConfig.serverBaseUrl}/points/rules/${rows.id}`;
    const config = { ...this.defaultAuthConfig() };
    const request = { ...rows };

    return authService.axiosService().put(url, request, config);
  };

  fetchBonusPoints = () => {
    const url = `${appConfig.serverBaseUrl}/bike/configs/bonus-points`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get(url, config);
  };
  createBonusPointsRule = rule => {
    const url = `${appConfig.serverBaseUrl}/bike/configs/bonus-points`;
    const config = { ...this.defaultAuthConfig() };
    const request = { ...rule };
    return authService.axiosService().post(url, request, config);
  };
  updateBonusPointsRule = rule => {
    const url = `${appConfig.serverBaseUrl}/bike/configs/bonus-points/${rule.id}`;
    const config = { ...this.defaultAuthConfig() };
    const request = { ...rule };
    return authService.axiosService().put(url, request, config);
  };

  fetchPointsEarning = (request) => {
    var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;


      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
      console.log("Current sort model", request.sortModel);
      if (request.sortModel.field && request.sortModel.sort) {
        sortField = request.sortModel.field;
        sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
      }
    }
    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark, actionType: request.actionType, transactionType: "ops" } } : {};

    const url = `${appConfig.serverBaseUrl}/points/rules`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get(url, config);
  };

  fetchReferralPointsRule = () => {
    const url = `${appConfig.serverBaseUrl}/referral-points/rules`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } };
    return authService.axiosService().get(url, config);
  }

  fetchBikeModels = () => {
    const url = `${appConfig.serverBaseUrl}/bike/models`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } };
    return authService.axiosService().get(url, config);
  };

  createBikeModelRule = (rule) => {
    const request = { ...rule };
    const url = `${appConfig.serverBaseUrl}/bike/models`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } };
    return authService.axiosService().post(url, request, config);
  };

  fetchPointsRequestAwarded = (request) => {
    console.log("hehehehe", request);
    console.log("audienceType", request.audienceType);
    console.log("award logs", request.segmentId);
    //  const request = { ...rule };
    const queryParams = request !== undefined ? { params: { requestId: request.requestId, audienceType: request.audienceType, audienceCategory: request.audienceCategory, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark, segmentId: request.segmentId, firstSortId: request.firstSortId ? request.firstSortId : null, lastSortId: request.lastSortId ? request.lastSortId : null, pageDelta: request.pageDelta ? request.pageDelta : null } } : {};

    const url = `${appConfig.serverBaseUrl}/points/awardedto`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get(url, config);
  };


  updateBikeModelRule = (rule) => {
    const request = { ...rule };
    const url = `${appConfig.serverBaseUrl}/bike/models/${rule.id}`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } };
    return authService.axiosService().put(url, request, config);
  };
  deleteBikeModelRule = rule => {
    const request = { ...rule, modelStatus: 'inactive' };
    const url = `${appConfig.serverBaseUrl}/bike/models/${rule.id}`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } };
    return authService.axiosService().put(url, request, config);
  };

  callDeleteRule = idx => {
    const url = `${appConfig.serverBaseUrl}/bike/configs/bonus-points/${idx}`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().delete(url, config);
  }

  fetchInvoiceRule = () => {
    const url = `${appConfig.serverBaseUrl}/invoice`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get(url, config);
  }
  updateInvoiceRule = (request) => {
    console.log(request);
    const url = `${appConfig.serverBaseUrl}/invoice`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put(url, request, config);
  }

};

const pointsApiService = new PointsApiService();
export default pointsApiService;