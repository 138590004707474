import { Button, makeStyles, TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import voucherConfigApiService from './APIService';
import { InputAdornment } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
const useStyles = makeStyles(theme => ({
    ...styles,
    saveButton: { color: 'floralwhite', backgroundColor: '#138DE8', margin: 2.5 },
    cancelButton: { color: 'floralwhite', backgroundColor: '#FF9017', margin: 2.5 },
    card: { width: 700 }
}));

export default function RuleForm(props) {
    var vouchers = []
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    vouchers = [...obj["Vouchers"]];


    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);
    const [openMultiple, setOpenMultiple] = React.useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [didFileUploadFail, setDidFileUploadFail] = useState(false);
    const [keyFileName, setKeyFileName] = useState("");
    const [currentRule, setCurrentRule] = useState({});
    const [ruleLoading, setRuleLoading] = useState(false);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [tierList, setTierList] = useState([]);
    const { createMode, ruleId } = props;
    const [hasError, setHasError] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [saveOpen, setSaveOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [period, setPeriod] = useState({});
    const [imageUrl, setImageUrl] = useState("")
    const [edit, setEdit] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open2, setOpen2] = useState(false);
    useEffect(() => {
        setProductLoading(true);
        voucherConfigApiService.fetchProductCategories()
            .then(response => {
                if (!response || !response.data) { return HWMessage.show("Product Categories cannot be fetched", "error"); }
                HWMessage.show("Request Successful", "success");
                setProductList(response.data.productCategoryList);
            })
            .catch(error => { return HWMessage.show(error.response.data.message || "Request could not be resolved", "error"); })
            .finally(() => setProductLoading(false));
    }, [])





    const periodConverter = periodString => {
        if (!periodString || periodString === '' || periodString === null)
            return {};
        var yidx = periodString.indexOf("Y");
        var midx = periodString.indexOf("M");
        var didx = periodString.indexOf("D");

        let result = {};
        if (yidx !== -1) result.years = parseInt(periodString.charAt(yidx - 1));
        if (midx !== -1) result.months = parseInt(periodString.slice(1, midx));
        if (didx !== -1) result.days = parseInt(periodString.charAt(didx - 1));
        setCurrentRule(prev => ({ ...prev, openValidityPeriodMonths: result.months }));

    };


    // COnverts to string
    const periodDeconverter = (years, months, days) => {
        let str = "P";

        if (years !== '' && years !== null && years !== undefined)
            str = str + years + "Y";
        if (months !== '' && months !== null && months !== undefined)
            str = str + months + "M";
        if (days !== '' && days !== null && days !== undefined)
            str = str + days + "D";

        console.log(str);
        return str;

    };


    useEffect(() => {
        if (props.createMode == false) {
            setProductLoading(true);
            voucherConfigApiService.fetchSpecificVoucher(props.ruleId)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Specific Voucher Cannnot be fetched", "error"); }
                    HWMessage.show("Request Successful", "success");
                    setCurrentRule(response.data);
                    periodConverter(response.data.openValidityPeriod);
                    console.log(response.data.imageFileName);
                    setImageUrl(response.data.imageFileName);
                })
                .catch(error => {
                    return HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                })
                .finally(() => setProductLoading(false));
        }

    }, [])



    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }



    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date(validityStart);
        var endDate = new Date(validityEnd);
        var currentDate = new Date();

        return !(endDate >= currentDate);
    };
    const isDisabled = false;

    const updateRule = useCallback(rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading(true);
            var tempRule = rule;

            tempRule.openValidityPeriod = periodDeconverter(rule.openValidityPeriodYears, rule.openValidityPeriodMonths, rule.openValidityPeriodDays);
            if (tempRule && tempRule.validityType === "Fixed" && tempRule.openValidityPeriod) {
                tempRule.openValidityPeriod = null;

            }
            if (tempRule && tempRule.validityType === "Open" && (tempRule.validityStart || tempRule.validityEnd)) {
                tempRule.validityStart = null;
                tempRule.validityEnd = null;

            }
            voucherConfigApiService.updateSpecificVoucher(tempRule)
                .then(response => {
                    if (!response || !response.data) { HWMessage.show("Error while fetching response", "error"); }
                    HWMessage.show("Request Successful", "success");
                    setCurrentRule(response.data);
                    successStatus = true;
                    props.setRefreshState(prev => !prev);

                    setOpen2(!open2);

                }).catch(error => {
                    HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    successStatus = false;
                })
                .finally(() => {
                    setRuleLoading(false);
                    if (successStatus === true)
                        console.log("sa");
                    props.setRefreshState(prev => !prev);
                });
        }
    });
    const createRule = useCallback(rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading(true);
            var tempRule = rule;

            tempRule.openValidityPeriod = periodDeconverter(rule.openValidityPeriodYears, rule.openValidityPeriodMonths, rule.openValidityPeriodDays);
            if (tempRule && tempRule.validityType === "Fixed" && tempRule.openValidityPeriod && tempRule.openValidityPeriod !== '') {
                tempRule.openValidityPeriod = null;

            }
            if (tempRule && tempRule.validityType === "Open" && (tempRule.validityStart || tempRule.validityEnd)) {
                tempRule.validityStart = null;
                tempRule.validityEnd = null;

            }
            voucherConfigApiService.createVoucher(tempRule)
                .then(response => {
                    if (!response || !response.data) { HWMessage.show("Error while fetchinmg response", "error"); }
                    setCurrentRule(response.data);
                    props.setRefreshState(prev => !prev);
                    successStatus = true;
                    setOpen3(!open3);

                    HWMessage.show("Request Successful", "success");
                }).catch(error => {
                    HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    successStatus = false;
                })
                .finally(() => {
                    setRuleLoading(false);
                    if (successStatus === true)
                        console.log("asa")
                });
        }
    });


    // Converts to JSON





    const captureChanges = event => {
        const field = event.target.name;
        const value = event.target.value;
        console.log(field, value);


        switch (field) {
            case 'categoryId': break;
            case 'amountReductionCeiling': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'voucherValue': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'maxUsageCeiling': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'minAmountRequired': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'percentDeduction': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'openValidityPeriodYears': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'openValidityPeriodMonths': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            case 'openValidityPeriodDays': setCurrentRule(prev => ({ ...prev, [field]: +value }));
                break;
            default:
                setCurrentRule(prev => ({ ...prev, [field]: value }));
        }

        console.log(currentRule);

    };
    const handleSave = () => {
        if (props.createMode === true) {

            createRule(currentRule);
        }
        else if (props.createMode === false) {

            updateRule(currentRule);
        }
    };
    const handleCancel = () => { props.setOpen(prev => false); };

    const uploadBulkFile = (file, fileName) => {

        setDidFileUploadFail(false);
        voucherConfigApiService.fetchS3PresignedUrl(fileName).then(response => {
            setDidFileUploadFail(false);
            console.log(response.data.presignedUrl);

            setKeyFileName(response.data.keyName);
            multibulkPartUpload(file, response.data.presignedUrl);

        }).catch((reason) => {
            setDidFileUploadFail(true);
            setUploadInProgress(true);
            setUploadInProgress(false);
        })
    }

    const multibulkPartUpload = (file, preSignedURL) => {
        voucherConfigApiService.uploadFile(file, preSignedURL).then(response => {

            setDidFileUploadFail(false);
            setUploadInProgress(false);

        }).catch((reason) => {
            setDidFileUploadFail(false);
            setUploadInProgress(false);

        })
    }

    const checkDisabled = () => {

        if (currentRule && currentRule.code && currentRule.code !== '' && currentRule.categoryName && currentRule.categoryName !== ''
            && currentRule.voucherType && currentRule.voucherType !== '' && currentRule.minAmountRequired >= 0
            && currentRule.maxUsageCeiling && currentRule.maxUsageCeiling > 0 && currentRule.validityType && currentRule.validityType !== ''
            && currentRule.description && currentRule.description !== '' && currentRule.prefix && currentRule.prefix !== '' && currentRule.imageFileName && currentRule.imageFileName !== '') {
            if ((currentRule && currentRule.voucherType && currentRule.voucherType === "Value" && currentRule.voucherValue >= 0)
                || (currentRule && currentRule.voucherType && currentRule.voucherType === "Discount" && currentRule.percentDeduction && currentRule.percentDeduction > 0 && currentRule.percentDeduction < 100 && currentRule.amountReductionCeiling && currentRule.amountReductionCeiling > 0)) {
                if ((currentRule && currentRule.validityType && currentRule.validityType === 'Fixed' && currentRule.validityStart && currentRule.validityStart !== '' && currentRule.validityEnd !== '' && currentRule.validityEnd)
                    || (currentRule && currentRule.validityType && currentRule.validityType === 'Open') && ((currentRule.openValidityPeriodMonths && currentRule.openValidityPeriodMonths !== '' && currentRule.openValidityPeriodMonths > 0))) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        }

        else
            return true;

    }


    return (
        <>           <BlockUi blocking={productLoading} message={'Please wait while we fetch.'} >

            <Card className={classes.card} >
                {props.createMode === true && <CardHeader style={{ margin: 0, padding: 5 }}> <center> <h3> Create Vouchers </h3>   </center>   </CardHeader>
                }
                {props.createMode === false && <CardHeader style={{ margin: 0, padding: 5 }}> <h3> Create Vouchers &nbsp; <a style={{ cursor: "pointer" }}><Edit style={{ color: "#138de8" }} onClick={e => setEdit(!edit)} /></a>  </h3>     </CardHeader>
                }
                <CardBody>
                    <GridContainer>
                        <GridItem xs={6} md={12} sm={6} >
                            <center>
                                <HWInput label={'Voucher Code'}
                                    name={'code'}
                                    hasError={checkError('code')}
                                    helperText={fetchErrorMessage('code')}
                                    onBlur={captureChanges}
                                    value={currentRule && currentRule.code}
                                    disabled={props.createMode === false ? !edit : false}
                                    required={true}
                                />

                            </center>

                        </GridItem>
                        <GridItem xs={6} md={12} sm={6} >
                            <br />
                        </GridItem>

                        <GridItem xs={6} md={6} sm={6} >
                            <DropDown
                                labelCategory="Voucher Category ID"
                                name="categoryId"
                                values={productList.map(i => i.categoryName)}
                                minWidth={300}
                                targetValue={currentRule && currentRule.categoryName}
                                onChange={e => { setCurrentRule(prev => { prev.categoryName = e.target.value; prev.categoryId = productList.filter(i => i.categoryName === e.target.value)[0].id; return { ...prev }; }); }}
                                onBlur={captureChanges}
                                helperText={fetchErrorMessage('categoryId')}
                                hasError={checkError('categoryId')}
                                disabled={props.createMode === false ? !edit : false}
                            />



                        </GridItem>
                        <GridItem xs={6} md={6} sm={6} >

                            <DropDown
                                labelCategory="Voucher Type"
                                name="voucherType"
                                minWidth={300}
                                values={['Value', 'Discount']}
                                targetValue={currentRule && currentRule.voucherType}
                                onChange={e => { setCurrentRule(prev => { prev.voucherType = e.target.value; return { ...prev }; }); }}
                                onBlur={captureChanges}
                                helperText={fetchErrorMessage('voucherType')}
                                hasError={checkError('voucherType')}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>


                        {currentRule && currentRule.categoryName === 'Digital Voucher' && <GridItem xs={6} md={12} sm={6} >
                            <HWInput label={'Voucher Purchase Points '} type='number'
                                name={'voucherPurchasePoints'} //add a column for voucher purchase points in voucher config table while creating voucher
                                helperText={(currentRule && currentRule.voucherPurchasePoints < 0) ? "Invalid Value" : ""}
                                hasError={currentRule && (currentRule.voucherPurchasePoints < 0)}
                                onBlur={captureChanges}
                                value={currentRule && currentRule.voucherPurchasePoints}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>
                        }

                        {<GridItem xs={6} md={6} sm={6} >
                            <HWInput label={'Min Txn. Value'} type='number'
                                name={'minAmountRequired'}
                                value={currentRule && currentRule.minAmountRequired}
                                helperText={(currentRule && currentRule.minAmountRequired < 0) ? "Invalid Value" : ""}
                                hasError={currentRule && currentRule.minAmountRequired < 0}
                                onBlur={captureChanges}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>
                        }

                        {currentRule && currentRule.voucherType === 'Value' && <GridItem xs={6} md={6} sm={6} >
                            <HWInput label={'Voucher Value (RM) '} type='number'
                                name={'voucherValue'}
                                helperText={(currentRule && currentRule.voucherValue < 0) ? "Invalid Value" : ""}
                                hasError={currentRule && currentRule.voucherValue < 0}
                                onBlur={captureChanges}
                                value={currentRule && currentRule.voucherValue}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>}

                        {currentRule && currentRule.voucherType === 'Discount' && <GridItem xs={6} md={12} sm={6} >
                            <HWInput label={'Percent Deduction '} type='number'
                                name={'percentDeduction'}
                                helperText={(currentRule && currentRule.percentDeduction <= 0) ? "Invalid Value" : ""}
                                hasError={currentRule && (currentRule.percentDeduction <= 0 || currentRule.percentDeduction > 100)}
                                onBlur={captureChanges}
                                value={currentRule && currentRule.percentDeduction}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>

                        }


                        <GridItem xs={6} md={12} sm={6} >
                            <HWInput label={'Max Usage Count '} type='number'
                                name={'maxUsageCeiling'}
                                value={currentRule && currentRule.maxUsageCeiling}
                                helperText={(currentRule && currentRule.maxUsageCeiling && currentRule.maxUsageCeiling <= 0) ? "Invalid Value" : ""}
                                hasError={currentRule && currentRule.maxUsageCeiling && currentRule.maxUsageCeiling <= 0}
                                onBlur={captureChanges}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>


                        {currentRule && currentRule.voucherType === 'Discount' && <GridItem xs={6} md={12} sm={6} >
                            <HWInput label={'Maximum Voucher Value'} type='number'
                                name={'amountReductionCeiling'}
                                value={currentRule && currentRule.amountReductionCeiling}
                                helperText={(currentRule && currentRule.amountReductionCeiling <= 0) ? "Invalid Value" : ""}
                                hasError={currentRule && currentRule.amountReductionCeiling <= 0}
                                onBlur={captureChanges}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />
                        </GridItem>}


                        <GridItem xs={6} md={6} sm={6} >
                            <DropDown
                                labelCategory="Validity Type"
                                name="validityType"
                                minWidth={300}
                                values={['Fixed', 'Open']}
                                targetValue={currentRule && currentRule.validityType}
                                onChange={e => { setCurrentRule(prev => { prev.validityType = e.target.value; return { ...prev }; }); }}
                                onBlur={captureChanges}
                                helperText={fetchErrorMessage('validityType')}
                                hasError={checkError('validityType')}
                                disabled={props.createMode === false ? !edit : false}
                                required={true}
                            />

                        </GridItem>



                        <GridItem xs={12} md={12} sm={12} >

                            <GridContainer >
                                {/* {currentRule && currentRule.validityType === 'Open' && <GridItem xs = {6} md = {4} sm = {6} > 
                                 <HWInput label = { 'Period ' } 
                                    name = { 'openValidityPeriodYears' }
                                    helperText = { fetchErrorMessage ('openValidityPeriodYears') }
                                    hasError = { currentRule && currentRule.openValidityPeriodYears <= 0  } 
                                    onBlur = { captureChanges } 
                                    endAdornment={<InputAdornment position="end">Years</InputAdornment>}
                                    value= {currentRule && currentRule.openValidityPeriodYears }
                                />   


                         </GridItem>
                         
    } */}

                                {currentRule && currentRule.validityType === 'Open' && <GridItem xs={6} md={4} sm={6} >
                                    <HWInput label={'Period '}
                                        name={'openValidityPeriodMonths'}
                                        helperText={fetchErrorMessage('openValidityPeriodMonths')}
                                        hasError={currentRule && (currentRule.openValidityPeriodMonths <= 0)}
                                        onBlur={captureChanges}
                                        endAdornment={<InputAdornment position="end">Months</InputAdornment>}
                                        value={currentRule && currentRule.openValidityPeriodMonths}
                                        disabled={props.createMode === false ? !edit : false}
                                        required={true}
                                    />


                                </GridItem>

                                }

                                {/* {currentRule && currentRule.validityType === 'Open' && <GridItem xs = {6} md = {4} sm = {6} > 
                                 <HWInput label = { 'Period ' } 
                                    name = { 'openValidityPeriodDays' }
                                    helperText = { fetchErrorMessage ('openValidityPeriodDays') }
                                    hasError = { currentRule  && (currentRule.openValidityPeriodDays <= 0 || currentRule.openValidityPeriodDays > 31)  } 
                                    onBlur = { captureChanges } 
                                    endAdornment={<InputAdornment position="end">Days</InputAdornment>}
                                    value= { currentRule && currentRule.openValidityPeriodDays}
                                />   


                         </GridItem>
                         
    } */}

                            </GridContainer>

                        </GridItem>

                        {props.createMode === false && <GridItem xs={12} md={6} sm={12} >

                            <HWInput label={'Utilized Count'}
                                name={'Count'}
                                value={currentRule && currentRule.maxUsageCeiling - currentRule.runningBalance}
                                disabled={true}
                                required={true}

                            />
                        </GridItem>
                        }

                        <GridItem xs={12} md={12} sm={12} >

                            <GridContainer >
                                {currentRule && currentRule.validityType === 'Fixed' && <GridItem xs={6} md={6} sm={6} >
                                    <HWInput type={'date'} name={'validityStart'}
                                        value={currentRule && currentRule.validityStart} onBlur={captureChanges}
                                        hasError={checkError('validityStart')}
                                        helperText={fetchErrorMessage('validityStart')}
                                        label={'Validity Start'}
                                        labelProps={{ shrink: true }}
                                        inputProps={{ min: new Date().toISOString().split('T')[0] }}
                                        // disabled={props.createMode === false ? (currentRule && (currentRule.validityStart <new Date().toISOString().split('T')[0]) ? true : false)   : false }
                                        disabled={props.createMode === false ? !edit : false}
                                        required={true}
                                    />
                                </GridItem>}




                                {currentRule && currentRule.validityType === 'Fixed' && <GridItem xs={6} md={6} sm={6} >
                                    <HWInput
                                        // disabled={props.createMode === false ? (currentRule && (currentRule.validityEnd < new Date().toISOString().split('T')[0]) ? true : false)   : false }
                                        type={'date'}
                                        name={'validityEnd'}
                                        value={currentRule && currentRule.validityEnd}
                                        onBlur={captureChanges}
                                        hasError={checkError('validityEnd')}
                                        helperText={fetchErrorMessage('validityEnd')}
                                        label={'Validity End'}
                                        disabled={props.createMode === false ? !edit : false}
                                        required={true}
                                        labelProps={{ shrink: true }}
                                        inputProps={{ min: (new Date().toISOString().split('T')[0] > currentRule.validityStart ? new Date().toISOString().split('T')[0] : currentRule.validityStart) }}
                                    />
                                </GridItem>}






                                <GridItem xs={6} md={12} sm={6} >
                                    <center>
                                        <HWInput label={'Voucher Prefix'}
                                            name={'prefix'}
                                            value={currentRule && currentRule.prefix}
                                            hasError={checkError('prefix')}
                                            helperText={fetchErrorMessage('prefix')}
                                            onBlur={captureChanges}
                                            disabled={props.createMode === false ? !edit : false}
                                            required={true}

                                        />
                                    </center>
                                </GridItem>


                                <GridItem xs={6} md={12} sm={6} >
                                    <center>
                                        <br />
                                        {<Button disabled={props.createMode === false ? !edit : false}
                                            raised variant="contained" style={{ backgroundColor: '#138de8', color: 'floralwhite', textTransform: 'none' }} onClick={() => setOpenSingle(true)}> Add Voucher Image </Button>}
                                        {imageUrl !== "" && <DropzoneDialog
                                            //  clearOnUnmount={ props.campaignMode === true ? true : false}
                                            clearOnUnmount={false}
                                            initialFiles={[imageUrl]}
                                            acceptedFiles={['image/*']}
                                            cancelButtonText={"cancel"}
                                            submitButtonText={"submit"}
                                            maxFileSize={5000000}
                                            dropzoneText={"  Please upload image with aspect-ratio  1.42:1 and dimensions 188px * 132px. "}
                                            dropzoneParagraphClass={"abc"}
                                            open={openSingle}
                                            filesLimit={1}
                                            onClose={() => setOpenSingle(false)}
                                            onDelete={(deletedFile) => setCurrentRule(prev => ({ ...prev, imageFileName: '' }))}
                                            disabled={props.createMode === false ? !edit : false}


                                            onSave={(bannerImageFile) => {
                                                //     console.log(' CurrentCampaign:', currentCampaign );

                                                console.log('Banner Image File:', bannerImageFile);


                                                setUploadInProgress(true);
                                                let myuuid = uuidv4();

                                                let file = bannerImageFile[0];
                                                let fileName = file.name;
                                                let fileNameUrl = fileName.substring(0, fileName.indexOf("."))
                                                if (file != null) {
                                                    let fileName = file.name
                                                    let fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
                                                    uploadBulkFile(file, myuuid + fileName);
                                                }


                                                setCurrentRule(prev => ({ ...prev, imageFileName: `${cloudfrontConfig.cloudfrontBaseUrl}/voucher-images/${myuuid + fileName}` }));

                                                setOpenSingle(false);
                                            }}
                                            showPreviews={true}
                                            showFileNamesInPreview={true}
                                            fullWidth={true}

                                        />}

                                        {imageUrl === "" && <DropzoneDialog
                                            //  clearOnUnmount={ props.campaignMode === true ? true : false}
                                            clearOnUnmount={false}
                                            initialFiles={[]}
                                            acceptedFiles={['image/*']}
                                            cancelButtonText={"cancel"}
                                            submitButtonText={"submit"}
                                            maxFileSize={5000000}
                                            dropzoneText={"  Please upload image with aspect-ratio  1.42:1 and dimensions 188px * 132px. "}
                                            dropzoneParagraphClass={"abc"}
                                            open={openSingle}
                                            filesLimit={1}
                                            onClose={() => setOpenSingle(false)}
                                            onDelete={(deletedFile) => setCurrentRule(prev => ({ ...prev, imageFileName: '' }))}


                                            onSave={(bannerImageFile) => {
                                                //     console.log(' CurrentCampaign:', currentCampaign );

                                                console.log('Banner Image File:', bannerImageFile);


                                                setUploadInProgress(true);
                                                let myuuid = uuidv4();

                                                let file = bannerImageFile[0];
                                                let fileName = file.name;
                                                let fileNameUrl = fileName.substring(0, fileName.indexOf("."))
                                                if (file != null) {
                                                    let fileName = file.name
                                                    let fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
                                                    uploadBulkFile(file, myuuid + fileName);
                                                }


                                                setCurrentRule(prev => ({ ...prev, imageFileName: `${cloudfrontConfig.cloudfrontBaseUrl}/voucher-images/${myuuid + fileName}` }));

                                                setOpenSingle(false);
                                            }}
                                            showPreviews={true}
                                            showFileNamesInPreview={true}
                                            fullWidth={true}

                                        />}

                                    </center>
                                </GridItem>




                                <GridItem xs={12} md={12} sm={12} >
                                    <center>

                                        <TextField
                                            style={{ marginTop: 20 }}
                                            multiline={true}
                                            rows={10}
                                            rowsMax={20}
                                            value={currentRule && currentRule.description}
                                            fullWidth
                                            variant="outlined"
                                            label={`Description`}
                                            placeholder='Please provide the description'
                                            name={'description'}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ maxLength: 500 }}
                                            // onBlur = { captureChanges }
                                            error={checkError('description')}
                                            onChange={captureChanges}
                                            helperText={fetchErrorMessage('description')}
                                            disabled={props.createMode === false ? !edit : false}
                                            required={true}
                                        />
                                    </center>
                                </GridItem>
                            </GridContainer>
                        </GridItem>


                        <GridItem xs={12} sm={12} md={12} >
                            {
                                <BlockUi blocking={ruleLoading} message={'Please wait...'} >
                                    <center>
                                        {vouchers.includes("Action") === true && props.createMode === true && <Button disabled={checkDisabled()} onClick={e => props.createMode === true ? setCreateOpen(true) : setSaveOpen(true)} raised variant="contained" style={{ backgroundColor: '#138DE8', color: 'floralwhite', textTransform: 'none' }} > {props.createMode === true ? 'Create Voucher' : 'Update Voucher'} </Button>
                                        }
                                        {vouchers.includes("Action") === true && props.createMode === false && edit === true && <Button disabled={checkDisabled()} onClick={e => props.createMode === true ? setCreateOpen(true) : setSaveOpen(true)} raised variant="contained" style={{ backgroundColor: '#138DE8', color: 'floralwhite', textTransform: 'none' }} > {props.createMode === true ? 'Create Voucher' : 'Update Voucher'} </Button>
                                        }
                                        <Button onClick={handleCancel} raised variant="contained" style={{ backgroundColor: '#FF9017', color: 'floralwhite', textTransform: 'none' }} > Cancel </Button>
                                        <ConfirmationDialog open={createOpen} setOpen={setCreateOpen} confirmingAction={handleSave} dialogMessage={'Are you sure you want to create this voucher'} dialogTitle={'Confirm Voucher Creation'} />


                                        <ConfirmationDialog open={saveOpen} setOpen={setSaveOpen} confirmingAction={handleSave} dialogMessage={'Are you sure want to update the voucher. '} dialogTitle={'Confirm voucher Updation'} />
                                        <StatusDialog isLoading={false} open={open3} setOpen={setOpen3} sucessStatus={"success"} confirmingAction={e => props.setOpen(false)} message={"Voucher successfully created."} dialogTitle={"Status"} />
                                        <StatusDialog isLoading={false} open={open2} setOpen={setOpen2} sucessStatus={"success"} confirmingAction={e => props.setOpen(false)} message={"Voucher successfully updated."} dialogTitle={"Status"} />

                                    </center>
                                </BlockUi>
                            }

                        </GridItem>

                    </GridContainer>
                </CardBody>
            </Card>
        </BlockUi>
        </>
    );

};