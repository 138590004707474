import { makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { People, Save } from '@material-ui/icons';
import settingsApiService from './APIService';
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter: { justifyContent: 'center', justifyItems: 'center' },
    cardHeader: { background: '#138DE8' },
    cardIcon: { background: '#138DE8' },
    bottomGrid: { marginTop: 10 },
    '& .MuiFormLabel-root': { marginBottom: 0 },
    editIcon: { color: '#138DE8' }
}));


export default function LuckyDraw(props) {
    HWLocalStorage.save(3, "previousSettingsTab");

    var settings = [];
    var obj = JSON.parse(HWLocalStorage.load("Permissions"));
    settings = [...obj["Settings"]];
    console.log("settings ", JSON.stringify(settings));

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [thresholdDate, setThresholdDate] = useState(null);
    const [edit, setEdit] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);  // Dialog state
    const [confirmAction, setConfirmAction] = useState(false); // Confirmation state

    useEffect(() => {
        setLoading(true);
        settingsApiService.getLuckyDrawThresholdDate()
            .then(response => {
                if (!response || !response.data) {
                    HWMessage.show("Data can't be fetched at the moment", "error");
                    return console.log("No relevant data can be fetched");
                }
                setThresholdDate(response.data.thresholdDate);
            })
            .catch(error => {
                HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const updateThresholdDate = (thresholdDate) => {
        setLoading(true);
        settingsApiService.updateLuckyDrawThresholdDate(thresholdDate)
            .then(response => {
                if (!response || !response.data) {
                    HWMessage.show("Data can't be fetched at the moment", "error");
                    return console.log("No relevant data can be fetched");
                }
                setThresholdDate(response.data.thresholdDate);
            })
            .catch(error => {
                HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    var newThresholdDate;
    if (thresholdDate !== undefined && thresholdDate !== null) {
        newThresholdDate = thresholdDate.split("T")[0];
    }

    const handleSaveClick = () => {
        // Show the confirmation dialog
        setOpenDialog(true);
    };

    const handleDialogClose = (confirm) => {
        if (confirm) {
            updateThresholdDate(newThresholdDate);  // Call the method to update the threshold date
        }
        setEdit(!edit);
        setOpenDialog(false);  // Close the dialog
    };


    return (
        <Fragment>
            <BlockUi>
                <Card id="top">
                    <CardHeader className={classes.cardHeader} color='rose' icon >
                        <CardIcon style={{ background: '#138DE8' }} color='primary'> <People /> </CardIcon>
                        <h4 className={classes.cardIconTitle} >
                            Lucky Draw
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer style={{ justifyContent: 'space-between' }}>
                            <GridItem xs={6} md={3} sm={6} >
                                <div style={{ display: "flex" }}>
                                    <HWInput
                                        type='date'
                                        disabled={!edit}
                                        name={'thresholdDate'}
                                        label={'Threshold Date'}
                                        labelProps={{ shrink: true }}
                                        value={newThresholdDate}
                                        onBlur={e => { setThresholdDate(e.target.value); }}
                                    />
                                    {settings.includes("Action") === true && edit === false &&
                                        <a style={{ cursor: "pointer", marginLeft: 10, position: "relative", top: 20 }}>
                                            <Edit style={{ color: "#138de8" }} onClick={e => setEdit(!edit)} />
                                        </a>
                                    }
                                    {settings.includes("Action") === true && edit === true &&
                                        <a style={{ cursor: "pointer", marginLeft: 10, position: "relative", top: 20 }}>
                                            <Save style={{ color: "#138de8" }} onClick={handleSaveClick} />
                                        </a>
                                    }
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{ paddingLeft: '15px', fontSize: '12px', fontStyle: 'Italic', color: '#8c8b8b' }}>
                            * Threshold Date determines the Lucky Draw entry points a member is eligible to receive for signing up or making a referral.
                        </GridContainer>
                    </CardBody>
                </Card>
            </BlockUi>

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    Are you sure you want to update the threshold date?
                </DialogContent>
                <DialogActions>
                    <Button style={{ backgroundColor: '#138DE8', color: 'floralwhite' }} onClick={() => handleDialogClose(false)} >
                        Cancel
                    </Button>
                    <Button variant="outlined" style={{ color: 'Grey', borderColor: '#0007003B' }} onClick={() => handleDialogClose(true)} >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};
