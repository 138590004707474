import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import BonusPoints from './BonusPoints';
import PointsEarningRules from './PointsEarningRules';
import PointsExpiryType from './PointsExpiryType';
import PurchaseReturnPoints from './PurchaseReturnPoints';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import ReferralPointsRule from './ReferralPointsRule';
const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch',
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator': { backgroundColor: '#FF9017' },
        "& .MuiButtonBase-root": { textTransform: "none" },
        color: "black",
        borderRadius: '6px'
    }
}));

export default function PointsRule(props) {
    HWLocalStorage.save(1, "previousPointsTab");

    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(JSON.parse(HWLocalStorage.load("previousPointsRuleTab")) || 0);;
    const changeTab = (event, idx) => { setSelectedTab(idx); }


    return (
        <Fragment>
            <Tabs variant='fullWidth' className={classes.root} value={selectedTab} onChange={changeTab}  >
                <Tab label="Points Expiry Type" />
                <Tab label="Points Earning Rules" />
                <Tab label="Bonus Points Rules" />
                <Tab label="Purchase and Return Points" />
                <Tab label="Referral Points Rules" />
            </Tabs>
            {
                {
                    0: <PointsExpiryType />,
                    1: <PointsEarningRules />,
                    2: <BonusPoints />,
                    3: <PurchaseReturnPoints />,
                    4: <ReferralPointsRule />  // add referral points rules when available in the API response
                }[selectedTab]
            }

            {/* <div style={{ padding: "30px 0px 30px 0px" }}>
                {selectedTab == 0 && <PointsExpiryType {...props} />}
                {selectedTab == 1 && <PointsEarningRules {...props} />}
                { selectedTab === 2 && <BonusPoints /> }
                { selectedTab === 3 && <PurchaseReturnPoints /> }
            </div> */}
        </Fragment>
    );
};

