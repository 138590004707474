import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, makeStyles } from '@material-ui/core';
import { GridAddIcon, DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Delete, Edit, People } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import React, { useState, useEffect, Fragment } from 'react';
import { useCallback } from 'react';
import RuleForm from './RuleForm';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import BorderLinearProgress from 'views/Members/BorderLinearProgress';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWGoogleMaps from 'views/Google Maps/HWGoogleMaps';
import axios from "axios";
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(theme => ({
    ...styles,
    cardHeader: { background: '#138DE8' },
    cardIcon: { background: '#138DE8' },
    deleteButton: { color: '#FF9017' },
    earningCategory: { color: '#138DE8', }
}));

function CustomLoadingOverlay() {
    return (
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
            <BorderLinearProgress />
        </div>
    );
}
function WarningDialog(props) {

    return (
        <Dialog>
            <DialogTitle> Are you sure you want to delete rule ? </DialogTitle>
            <DialogContent >
                <DialogContentText > No overlapping rules exist for fallback. This rule cannot be deleted. </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

function RuleDialog(props) {

    const [ruleLoading, setRuleLoading] = useState(false);
    const [ruleDescription, setRuleDescription] = useState({});

    const handleClose = event => { props.setRuleId(null); props.setOpen(false); };
    return (
        <>
            <Dialog open={props.open} onClose={handleClose}  >
                <RuleForm isReferralRule={true} refreshState={props.refreshState} setRefreshState={props.setRefreshState} rules={props.rules} setRules={props.setRules} createMode={props.createMode} ruleId={props.ruleId} open={props.open} setOpen={props.setOpen} />
            </Dialog>
        </>
    );

}

export default function ReferralPointsRule(props) {
    HWLocalStorage.save(1, "previousPointsRuleTab");

    var point = []
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    point = [...obj["Points"]];

    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [rowCount, setRowCount] = useState(0);
    const [filterModel, setFilterModel] = useState({});
    const [sortModel, setSortModel] = useState({});

    const [openCreatePopup, setOpenCreatePopup] = useState(false);
    const [rulesLoading, setRulesLoading] = useState(false);
    const [searchText, setSearchText] = useState("")
    const [deleteObject, setDeleteObject] = useState(null);
    const [warningOpen, setWarningOpen] = useState(false);
    const [selectedRuleId, setSelectedRuleId] = useState();
    const [ruleOpen, setRuleOpen] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [rows, setRows] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletingRule, setDeletingRule] = useState(null);
    const [refreshState, setRefreshState] = useState(false);
    const [location, setLocation] = useState({ lat: -37.6969490, lng: 150.456778 });
    const [address, setAddress] = useState("");

    const dummyResponse = {
        "referralRuleList": [{
            "id": "MerchantReferralPointConfig",
            "entity": "Merchant",
            "multiplier": "1",
            "points": "50",
            // "raisedDate": "2025-01-08T12:39:28.355Z",
            "validityEnd": "9999-12-31",
            "validityStart": "2025-01-08",
            "createTime": "2025-01-08T08:29:01.307Z",
            "updateTime": "2025-01-08T08:29:01.307Z"
        },
        {
            "id": "MemberReferralPointConfig",
            "entity": "Member",
            "multiplier": "1",
            "points": "100",
            // "raisedDate": "2025-01-08T12:39:28.355Z",
            "validityEnd": "9999-12-31",
            "validityStart": "2025-01-08",
            "createTime": "2025-01-08T08:29:01.307Z",
            "updateTime": "2025-01-08T08:29:01.307Z"
        }],
        "totalHits": 2
    };


    useEffect(() => {
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;

        // setRulesLoading(true);
        console.log("dummy data: ", dummyResponse);
        setRows(dummyResponse.referralRuleList);
        setRowCount(dummyResponse.totalHits);

        // pointsApiService.fetchReferralPointsRule().then(response => {
        //     if (!response || !response.data)
        //         return HWMessage.show("No relevant data can be fetched", "error");
        //     console.log(response.data);
        //     // setRows(response.data.pointsRuleList);
        //     setRowCount(2)//(response.data.totalHits);

        //     HWMessage.show("Request Successful", "success");
        // })
        // .catch(error => {
        //     setRulesLoading(false); HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        // })
        // .finally(() => {
        //     setRulesLoading(false);
        //     var my_element = document.getElementById("top");
        //     if (my_element !== null || my_element !== undefined || my_element !== '') {
        //         // my_element.scrollIntoView({ }); 
        //     }
        // });

        // pointsApiService.fetchPointsEarning({ fetchLimit, paginationBookmark, filterModel, sortModel, searchText }).then(response => {
        //     if (!response || !response.data)
        //         return HWMessage.show("No relevant data can be fetched", "error");
        //     setRows(response.data.pointsRuleList);
        //     setRowCount(response.data.totalHits);

        //     HWMessage.show("Request Successful", "success");
        // })

        //     .catch(error => {
        //         setRulesLoading(false); HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        //     })
        //     .finally(() => {
        //         setRulesLoading(false);
        //         var my_element = document.getElementById("top");
        //         if (my_element !== null || my_element !== undefined || my_element !== '') {
        //             // my_element.scrollIntoView({ }); 

        //         }

        //     });
    }, [page, pageSize, filterModel, sortModel, searchText, refreshState]);

    const deleteRule = useCallback(deleteObject => {
        if (deleteObject != null) {
            setRulesLoading(true);
            deleteObject.ruleStatus = 'Inactive';
            pointsApiService.updateRule(deleteObject)
                .then(response => {
                    if (!response || !response.data) { return HWMessage.show("Error while deleteing the rule", "error"); }

                    setRowCount(rowCount - 1);
                    setRows(prev => { prev = prev.filter(i => i.id !== deleteObject.id); return [...prev] });
                    HWMessage.show("Request Successful", "success");
                })
                .catch(error => {
                    return HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                })
                .finally(() => { setRulesLoading(false) });
            setFilterModel(prev => { });
            setSortModel(prev => { });
        }
    });

    const showWarning = deleteObject => {
        setWarningOpen(true);
        setDeleteObject(deleteObject);
    };

    const checkPriorDelete = deleteObject => {
        // Check for any existing rules which may be applicable for the time being
        return true;
    };
    const handleDelete = (event, deleteObject) => {
        var check = checkPriorDelete(deleteObject);
        if (check === true) deleteRule(deleteObject);
        else showWarning(deleteObject);

        setDeletingRule(null);
    };
    const openRule = () => { setRuleOpen(true); };
    const addRule = (event) => {
        setCreateMode(prev => true);
        setRuleOpen(prev => true);
    };
    const handleClick = (event, id) => { setRuleOpen(true); };

    const handleRefresh = (refresh) => {
        setFilterModel(prev => { });
        setSortModel(prev => { });
        setRefreshState(prev => refresh);
    };

    const columns = [
        {
            field: 'entity', headerName: 'Earning Entity', width: 200, renderCell: cellParams => {
                return <span >  {cellParams.row.entity}  </span>
            }
        },
        { field: 'multiplier', filterable: false, headerName: 'Multiplier', flex: 2 },
        { field: 'points', filterable: false, headerName: 'Points', flex: 2 },
        {
            field: 'validity', filterable: false, headerName: 'Validity', flex: 3.5, renderCell: cellParams => {
                var { validityStart, validityEnd } = cellParams.row;
                var formattedStart = new Date(validityStart).toLocaleDateString();
                var formattedEnd = new Date(validityEnd).toLocaleDateString();

                return <span onClick={e => openRule(e, cellParams.row.id)} > {`${formattedStart} - ${formattedEnd}`} </span>;
            }
        },
        {
            field: 'createTime', filterable: false, headerName: 'Raised Date', width: 170, renderCell: cellParams => {
                var date = cellParams.row.createTime;
                var dateString = date.split('T');
                var d = new Date(dateString).toLocaleDateString();

                return <span> {d} </span>
            }
        },

    ];

    if (point.includes("Action") === true) {

        columns.push({ field: 'Edit', headerName: '', filterable: false, sortable: false, flex: 2, renderCell: cellParams => <> <IconButton onClick={e => { setSelectedRuleId(cellParams.row.id); if (cellParams.row.id !== null) handleClick(); }} > <Edit style={{ color: '#138DE8' }} /> </IconButton>  </> });
        columns.push({
            field: 'Delete', filterable: false, headerName: '', flex: 1.5, renderCell: cellParams => {
                return (<>
                    <IconButton className={classes.deleteButton} onClick={e => { setDeleteOpen(true); setDeletingRule(cellParams.row) }} > <Delete /> </IconButton>
                </>);
            }
        })
    }

    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell': { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)': { backgroundColor: 'white' },
            '& .MuiButton-label': { color: '#138DE8' }
        }
    }));
    const dataGridClasses = useDataGridStyles();

    const handleOpen = openState => {
        if (!openState || openState === false) setSelectedRuleId(prev => null);
        setRuleOpen(prev => openState);
    };

    return (
        <Fragment>
            <GridContainer id="top">
                <GridItem xs={12} md={12} sm={12} >
                    <Card >
                        <CardHeader className={classes.cardHeader} color='rose' icon >
                            <CardIcon style={{ background: '#138DE8' }} color='primary'> <People /> </CardIcon>
                            <h4 className={classes.cardIconTitle} > Points Earning Rules</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{ justifyContent: 'end' }} >
                                <GridItem>
                                    {point.includes("Action") === true && <Fab variant='extended' aria-label="add" style={{ marginBottom: 10, background: '#FF9017', color: 'white' }} onClick={addRule}  > <GridAddIcon />  Add Rule </Fab>}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} md={12} sm={12} >
                                    <DataGrid
                                        className={dataGridClasses.root}
                                        rows={rows}
                                        columns={columns}
                                        page={page}
                                        pageSize={pageSize}
                                        rowCount={rowCount}
                                        autoHeight
                                        loading={rulesLoading}
                                        pagination
                                        density="compact"
                                        paginationMode='server'
                                        onPageChange={changeParams => setPage(changeParams)}
                                        onPageSizeChange={changeParams => { setPage(0); setPageSize(changeParams); }}
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }}
                                        showToolbar
                                        sortingMode="server"
                                        onSortModelChange={params => {
                                            if (params.length > 0)
                                                setSortModel(params[0]);
                                        }}

                                        filterMode='server'
                                        onFilterModelChange={(params) => {
                                            if (params.items.length > 0) {
                                                setFilterModel(prev => ({ ...params.items[0] }));
                                            }
                                        }}
                                        rowsPerPageOptions={[25, 50, 100]}
                                    />
                                </GridItem>
                            </GridContainer>

                            <WarningDialog open={warningOpen} setOpen={setWarningOpen} warningObject={deleteObject} />
                            {<RuleDialog
                                ruleId={selectedRuleId}
                                setRuleId={setSelectedRuleId}
                                open={ruleOpen}
                                setOpen={handleOpen}
                                createMode={createMode}
                                rules={rows}
                                setRules={setRows}
                                refreshState={refreshState}
                                setCreateMode={setCreateMode}
                                setRefreshState={setRefreshState}
                            />}
                            <ConfirmationDialog
                                dialogTitle={'Confirm Rule Deletion'}
                                dialogMessage={'Are you sure you want to delete the rule. It will affect the current awarding process.'}
                                confirmingAction={e => handleDelete(e, deletingRule)} open={deleteOpen} setOpen={setDeleteOpen}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Fragment>
    );
};