import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Fragment } from 'react';
import MembershipCategory from "./MembershipCategory";
import Communication from "./Communication";
import RolesPermission from "./RolesPermission";
import Subscription from "./Subscription";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import LuckyDraw from './LuckyDraw';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch',
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator': { backgroundColor: '#FF9017' },
        "& .MuiButtonBase-root": { textTransform: "none" },
        color: "black",
        borderRadius: '6px'
    }
}));

export default function Settings(props) {

    const classes = useStyles();
    if (HWLocalStorage.load("previousVouchersTab") !== null)
        HWLocalStorage.clear("previousVouchersTab");
    if (HWLocalStorage.load("previousVouchersRuleTab") !== null)
        HWLocalStorage.clear("previousVouchersRuleTab");
    if (HWLocalStorage.load("previousCardTab") !== null)
        HWLocalStorage.clear("previousCardTab");
    if (HWLocalStorage.load("previousMemberTab") !== null) {
        HWLocalStorage.clear("previousMemberTab")
    }
    if (HWLocalStorage.load("previousActionsTab") !== null) {
        HWLocalStorage.clear("previousActionsTab")
    }

    if (HWLocalStorage.load("previousMerchantDetailsTab") !== null) {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if (HWLocalStorage.load("previousMerchantTab") !== null) {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if (HWLocalStorage.load("previousPointsRuleTab") !== null) {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if (HWLocalStorage.load("previousPointsTab") !== null) {
        HWLocalStorage.clear("previousPointsTab");
    }

    if (HWLocalStorage.load("previousActionsTab") !== null) {
        HWLocalStorage.clear("previousPointsTab");
    }
    if (HWLocalStorage.load("previousPointsActionsTab") !== null) {
        HWLocalStorage.clear("previousPointsActionsTab");
    }
    const [currentTab, setCurrentTab] = useState(JSON.parse(HWLocalStorage.load("previousSettingsTab")) || 0);
    const changeTab = (event, idx) => { setCurrentTab(idx); }

    return (
        <Fragment>
            <Tabs variant='scrollable' className={classes.root} value={currentTab} onChange={changeTab} >
                <Tab label="Communication" />
                <Tab disabled={HWLocalStorage.load("ops-user").roles.includes("Admin") !== true ? true : false} label="Roles and Permission" />
                {/* <Tab label = "Roles and Permission" /> */}
                <Tab label="Membership Category" />
                <Tab label="Subscription" />
                <Tab label="Lucky Draw" />
            </Tabs>
            <div style={{ padding: "15px 0px 30px 0px" }}>{
                {
                    0: <Communication />,
                    1: <RolesPermission />,
                    // 1 : HWLocalStorage.load("ops-user").roles.includes("Admin")===true  && <RolesPermission /> ,
                    2: <MembershipCategory />,
                    3: <Subscription />,
                    4: <LuckyDraw />
                }[currentTab]
            }
            </div>
        </Fragment>
    );

};

